import { Box, Button, HStack } from '@medely/ui-kit';
import React from 'react';

export interface TaxInfoBusinessFooterProps {
  cancelAction?: () => void;
  saveAction: () => void;
  isLoading?: boolean;
}

export const TaxInfoBusinessFooter = ({
  saveAction,
  cancelAction,
  isLoading,
}: TaxInfoBusinessFooterProps) => {
  return (
    <HStack>
      <Box width="50%" ml={0.5}>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            cancelAction?.();
          }}
        >
          Cancel
        </Button>
      </Box>
      <Box width="50%" ml={0.5}>
        <Button
          loading={isLoading}
          fullWidth
          onClick={() => {
            saveAction();
          }}
        >
          Save
        </Button>
      </Box>
    </HStack>
  );
};

export default TaxInfoBusinessFooter;
