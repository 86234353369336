import { Box, Heading, HStack, Text, SingleSelectField, TextField, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '@src/components/FixedFooterLayout';
import React from 'react';
import { useBusinessTab } from './useBusinessTab';
import { useFormContext } from 'react-hook-form';
import type { IStripeAccount } from '@medely/types';
import type { TaxInfo } from '@src/models/settings/tax-info/TaxInfo.schema';
import { TaxInfoBusinessFooter } from './components/TaxInfoBusinessFooter';
import { useIsMutating } from '@tanstack/react-query';
import { TaxInfoBusinessLegalRepresentativeInputs } from './components/TaxInfoBusinessLegalRepresentativeInputs';
import type { NotificationT } from './types';

type TaxInfoBusinessFormProps = {
  account: IStripeAccount | null;
  notification: NotificationT;
};

export const TaxInfoBusinessForm = ({ account, notification }: TaxInfoBusinessFormProps) => {
  const { taxClassificationOptions, stateOptions, onSubmit, handleGoBack } = useBusinessTab({
    account,
    notification,
  });
  const { handleSubmit } = useFormContext<TaxInfo>();
  const isMutating = useIsMutating();

  return (
    <FixedFooterLayout
      footerContent={
        <TaxInfoBusinessFooter
          saveAction={() => handleSubmit(onSubmit)()}
          cancelAction={() => handleGoBack()}
          isLoading={!!isMutating}
        />
      }
    >
      <VStack gap={2}>
        <SingleSelectField
          label="Tax classification"
          name="tax_classification_id"
          options={taxClassificationOptions}
        />

        <TextField label="Legal business name" name="company_name" />

        <TextField
          label="EIN"
          name="personal_id_number"
          formatOnChange={(v) => (v || '').replace(/[^0-9]/g, '')}
        />
        <Box mt={2}>
          <Heading size="xs" testId="tax-info-home-address-title">
            Address
          </Heading>

          <Text size="s">The primary location where business activity is conducted.</Text>
        </Box>

        <TextField
          label="Street Address"
          name="address_first"
          testId="tax-info-home-address-first"
        />

        <HStack gap={1}>
          <Box width="50%">
            <TextField
              label="Address 2"
              name="address_second"
              testId="tax-info-home-address-second"
            />
          </Box>

          <Box width="50%">
            <TextField label="City" name="address_city" testId="tax-info-home-address-city" />
          </Box>
        </HStack>

        <HStack gap={1}>
          <Box width="50%">
            <SingleSelectField label="State" name="state_id" options={stateOptions} />
          </Box>

          <Box width="50%">
            <TextField
              label="ZIP Code"
              name="address_zip"
              testId="tax-info-home-address-state"
              formatOnChange={(v) => (v || '').replace(/[^0-9]/g, '')}
            />
          </Box>
        </HStack>
        <TaxInfoBusinessLegalRepresentativeInputs stateOptions={stateOptions} />
      </VStack>
    </FixedFooterLayout>
  );
};
